.centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f0f0;
}
  
.centeredForm {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    min-height: 60vh;
}
  
.heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
}
  
.submitButton {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.centeredForm input {
    margin-bottom: 10px;
}