

.loadingIndicatorContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(97, 97, 97, 0.8);
    z-index: 9999; 
  }
  