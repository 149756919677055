.linkText {
    margin-right: 10px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 2px;
    background-color: rgb(239, 235, 235);
}

.downloadButton {
    margin-bottom: 10px;
}