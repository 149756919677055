.centeredForm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.heading {
  margin-bottom: 30px;
  text-align: center;
}

.btnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}