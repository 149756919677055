.container {
    height: 100vh;
    padding: 10vh 7vw;
    display: grid;
    grid-template-rows: 1fr 4fr;
}

.questionContainer {
    font-size: 20px;
}

.actionContainer { 
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button {
    background-color: var(--blue);
    color: white;
    padding: 20px auto;
    border: none;
}

.button:hover {
    background-color: var(--blue-light);
    color: white !important;
}

.recorder {
    display: none;
}

.voice {
    margin-bottom: 100px;
}