.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 5vh 5vw;
}

.tableContainer {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.heading {
    margin: 15vh 0;
}

.button {
    width: 150px;
    margin-bottom: 10px;
}

.table {
    width: 100%;
}