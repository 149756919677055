/* styles.module.css */
.container {
    padding: 40px 60px 40px 60px;
}

.heading {
    margin-bottom: 20px;
    text-align: center;
}

.urlRow {
    margin-top: 20px;
}

.urlInput {
    width: 100%;
}

.copyButton {
    margin-left: 10px;
}
  
.dashboardButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}