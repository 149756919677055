.container {
    height: 100vh;
    display: grid;
    place-items: center;
    grid-template-rows: 1fr;
}

.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.interviewText {
    font-size: 24px;
    margin-bottom: 10vh;
}

.button {
    background-color: var(--blue);
    color: white;
    font-size: 16px !important;
    border: none;
}

.button:hover {
    background-color: var(--blue-light);
    color: white !important;
}