.container {
    display: flex;
    padding: 100px;
    flex-direction: column;
}

.mainHeading {
    margin-bottom: 80px !important;
}

.jobDescription {
    margin-bottom: 30px;
}

.readMore {
    margin-left: 20px;
    cursor: pointer;
    color: blue;
    font-weight: bold;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}