.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.formContainer {
    width: 450px;
    padding: 40px 20px;
    background-color: #ffffff; 
    border: 1px solid #e8e8e8; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 4px;
}
  
.heading {
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
}

.modalHeading {
    margin-bottom: 30px;
}

.roleButtonContainer button {
    width: 100px;
}

.roleButtonContainer {
    display: flex;
    justify-content: center;
}