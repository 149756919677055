.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 30px;
}
  

.outerCard {
    width: 40vw;
}

.orgCard {
    margin-bottom: 20px;
}