* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #635e5e;
  --blue-light: #635e5e;
  --white-bg: #f5f4f1;
}

body {
}