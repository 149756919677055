.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }

  .heading {
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 20px;
  }